.theme-dark {
  background-color: #36393f;

  .primary-background {
    background-color: #36393f;
  }

  .secondary-background {
    background-color: #2F3136;
  }
  
  .input-group-prepend {
    background-color:#33353a;
    border-color: #2F3136;
    color: white;
  }

  .dark-button {
    color: #36393f;
  }

  .dropdown-menu, 
  .dropdown-item {
    background: #36393f;
  }

  .dropdown-item {
    color: white;
  }

  .dropdown-item:hover,
  .dropdown-item:active,
  .dropdown-item:focus {
    background-color: #80b3ffb2;
  }

  .form-control {
    border: none;
  }

  .card {
    background-color: #32353a;
  }

  .disabled {
    color: #7a7a7a;
  }

  .text-inverse {
    color: #fff
  }

  .text-inverse:hover {
    color: #fff
  }

  .text-inverse-75 {
    color: rgba(255, 255, 255, 0.75);
  }
  
  .text-inverse-50 {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .text-inverse-25 {
    color: rgba(255, 255, 255, 0.25) !important;
  }

  .custom-input-background > .input-group-text {
    background: #32353a;
    border-style: none;
  }

  .nav {
    border: none !important;
  }
    
  .nav-tabs .nav-link {
    background-color: transparent !important;
    border: none !important;
    border-bottom: solid 1px rgba(255, 255, 255, 0.5) !important;
    color: rgba(255, 255, 255, 0.5);
  }
  
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link.active {
    background-color: #2F3136 !important;
    color: white !important;
  }
  
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-bottom: solid 1px rgba(255, 255, 255, 0.5) !important;
  }
  
  .nav-tabs .nav-link.active {
     border-bottom: solid 3px rgba(255, 255, 255, 0.5) !important;
  }
    
  .dropdown-inverse-highlight:hover {
    color: black !important;
  }

  .td-inverse-highlight:hover {
    color: #007bff !important;
  }

  .inner-card {
    background-color: #3b3f45;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    .custom-input-background > .input-group-text {
      background: #3b3f45;
      border-style: none;
    }
  }

  .inner-card-other {
    background-color: #494c53;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    .custom-input-background > .input-group-text {
      background: #494c53;
      border-style: none;
    }
  }


  ::-webkit-scrollbar-track {
    background-color: #36393f;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: #7a7a7a;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #36393f; 
    border-radius: 10px;
    border: 3px solid #545454;
  }

  .scrollable-container {
    &::-webkit-scrollbar-track {
      background-color: #36393f;
    }
  
    &::-webkit-scrollbar {
      width: 0.2rem;
      background-color: #36393f;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #36393f; 
      border-radius: 10px;
    }
  }

  .sticky-header {
    background: #36393f;
  }

  .list-row:nth-child(odd) {
  background-color: #36393f !important;
  }
  
  .list-row:nth-child(even) {
    background-color: #32353a !important;
  }

  .form-control {
    &::-webkit-scrollbar {
        background-color: #e5e5e5;
        width: 0.4rem;   
    }
    &::-webkit-scrollbar-track {
        background-color: #d2d2d2;
        border-radius: 0.25rem;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #a1a1a1;
        border: 3px solid #989898;
        border-radius: 0.25rem;
    } 
  }

  .language-json {
    background-color: #000000;
    border-radius: 0.5rem;
    color: #b9b9b9;
    font-size: 14px;
  }
  
  .language-json .token {
    text-shadow: none;
    background: none;
    background-color: #000000;
  }
  
  .language-json .token.string,
  .language-json .token.number,
  .language-json .token.boolean {
    color: #7fe984;
  }
  
  .language-json .token.property {
    color: #d96fa7;
  }
  
  .language-json .token.punctuation,
  .language-json .token.operator {
    color: #cecece;
  }
}

.modal-dark {
  .modal-content {
    background-color: #2F3136;
    color: #fff;
  }

  .subtle-border {
    border-color:#494c51; 
  }

  .modal-header {
    background-color:  #2a2c30;
    color: rgba(255, 255, 255, 0.75);
    border-color: #494c51;
  }

  .modal-body, .modal-footer {
    background-color: #2F3136;
    color: #b2b2b2;
    border: none;
  }

  .modal-title {
    color: #a1a1a1;
  }

  .input-group,
  .input-group-prepend,
  .input-group-append {
    background-color: #2F3136;
    border-color:  #2F3136;
    color: white;
  }

  .input-group-text {
    background-color: #494c51;
    border: none;
    color: white;
  }

  .form-control {
    background-color: #2a2c30;
    border-color: #494c51;
    color: white;

    &:-webkit-autofill {
      background-color: #2a2c30 !important;
      color: white !important;
      -webkit-text-fill-color: white !important;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  .card-header, .card-footer {
    background-color: #32353a;
    color: #fff;
  }
  
  .card {
    background-color: #2F3136;
    color: #fff;
    border-radius: 0px;
  }
  
  .btn-close {
    filter: invert(1);
  }
  
  .dropdown-item, .dropdown-menu {
    background: #36393f;
    color: white;
  }

  .dropdown-item:hover,
  .dropdown-item:active,
  .dropdown-item:focus {
    background-color: #80b3ffb2;
  }

  .accordion {
    padding: 6px;
  }

  .accordion-item {
    background-color: #32353a;
    border: none;
    margin-bottom: 5px;
    border-radius: 0px;
    color: #fff;

    .accordion-header,
    .accordion-button {
      background-color: #2F3136;
      color: #fff;
      border-radius: 0px;
    }

    .accordion-body {
      background-color: #3b3f45;
      color: #fff;
      border-radius: 0px;
    }

    .accordion-button {
      .accordion-button::after {
        display: none;
      }
      &:focus {
        outline: 0;
        box-shadow: none; 
      }
    }
  }

  .accordion-item:last-child {
    margin-bottom: 0;
  }

  .language-json {
    background-color: #000000;
    border-radius: 5px;
    color: #b9b9b9;
  }
  
  .language-json .token {
    text-shadow: none;
    background: none;
    background-color: #000000;
  }
  
  .language-json .token.string,
  .language-json .token.number,
  .language-json .token.boolean {
    color: #7fe984;
  }
  
  .language-json .token.property {
    color: #d96fa7;
  }
  
  .language-json .token.punctuation,
  .language-json .token.operator {
    color: #cecece;
  }
}