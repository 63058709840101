.custom-funnel-button {
    height: 25px;
    margin-top: 7px;
}

.custom-card-body {
    padding: 0rem;
    margin: 0.2rem;
    border: none;
}

.align-left-onwrap {
    text-align: left;
    white-space: normal;
    word-wrap: break-word;
}

.custom-card-body-children {
    padding-top: 0rem;
    padding-bottom: 0.1rem;
    padding-left: 1.3rem;
    padding-right: 0.5rem;
    border: none;
    margin-bottom: 1px;
}

.custom-card-product {
    margin: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.revision-title {
    padding-left: 1.3rem;
}
.client-release-title {
    padding-left: 1.3rem;
    padding-bottom: 0.2rem;
}

.daily-impr-product {
    padding-left: 0.7rem;
    padding-right: 0;
}

.highlight-card:focus-within {
    border-color: #838383;
    border-style: solid;
}

.highlight-revision-card {
    box-sizing: border-box;
    border: 0.1rem transparent solid;
}

.highlight-revision-card:focus-within {
    border-color: #838383 !important;
    border-style: dotted;
}

/* FontAwesomeIcon Progress Bar setup*/
.hr-line {
    width: 5px;
    height: 50%;
    border-bottom: 2px solid gray;
}

.icon-chevron {
    font-size: 0.5rem;
    margin-bottom: 0.1rem;
}

.icon-placeholder {
    width: 0.4rem;
}

.no-wrap {
    flex-wrap: nowrap;
}

.product-title {
    font-weight: 500;
}

.project-title {
    padding: 0 1rem 0 1.4rem;
}

.rotate-image-animation {
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}
.rotate-180 {
    transform: rotate(180deg);
    transition: transform 0.3s ease-out;
}

.spinner-sm {
    max-height: 24px;
}

.scrollable-container {
    overflow-y: auto;
    // Relative to the height of sticky element, change if that element size is changed
    min-height: calc(100vh - 193px);
    max-height: calc(100vh - 193px);
}

.sortable-titles {
    font-size: 0.85rem;
    flex-wrap: nowrap;
    margin-top: 0.2rem;
    margin-bottom: 0.1rem;
    display: flex;
    align-items: center;
}

.title-dropdown {
    outline: none;
    box-shadow: none;
    background-color: inherit !important;
}

.transparentListItem {
    background: none;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.pressedButton {
    filter: brightness(70%);
}
